<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >

  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "quality-library-edit-form",
  mixins: [],
  components: {},

  props: {
    scale: {type: Object, required: true},
  },

  computed: {},

  watch: {},

  mounted() {
    // this.$nextTick(function () {
    //   this.$refs.editForm.clearValidate();
    // });

  },

  data() {
    return {

      rules: {}

    }
  },

  methods: {}
}
</script>

<style>

</style>